import React, { useState, useEffect } from "react";
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import { Button } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import ReactPlayer from 'react-player/lazy'
import ModalTiny from "react-bootstrap/Modal"

const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return (
        createPortal(
            children,
            el
        )
    )
}


const CustomVideo = (props) => {
    const [isOpen, setOpen] = useState(false);

    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }

    return (
        <>
            {props.type == "button" ?
                <Button type="button" variant="link" className="video-btn" onClick={() => { setOpen(true); trackerVideo(props.title); }} onKeyDown={() => setOpen(true)}>
                    <i className={props.icon ? props.icon : "video-icon"}></i>
                    {props.buttonName &&
                        <>{props.buttonName}</>
                    }
                </Button>
                :
                <strong className={props.class ? props.class : "video-btn"} onClick={() => { setOpen(true); trackerVideo(props.title); }} onKeyDown={() => setOpen(true)}>
                    <i className={props.icon ? props.icon : "video-icon"}></i>
                    {props.buttonName &&
                        <>{props.buttonName}</>
                    }
                </strong>
            }
            {isOpen &&

                <ModalTiny show={isOpen} onHide={() => setOpen(false)} className="player-modal">
                    <ModalTiny.Header className="contact-close-btn ">
                        <span class="close-menu" onClick={() => setOpen(false)} ><i class="icon-close"></i></span>
                    </ModalTiny.Header>
                    <ModalTiny.Body className="event-section">
                        <ReactPlayer url={props.url} width="100%" height="100%" playing />
                    </ModalTiny.Body>
                </ModalTiny>
            }
        </>
    )
}

export default CustomVideo

